@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?28ea0131d0252c116bf7609ebf739b22?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?28ea0131d0252c116bf7609ebf739b22") format("woff2"),
url("./cy-custom-icons.woff?28ea0131d0252c116bf7609ebf739b22") format("woff"),
url("./cy-custom-icons.ttf?28ea0131d0252c116bf7609ebf739b22") format("truetype"),
url("./cy-custom-icons.svg?28ea0131d0252c116bf7609ebf739b22#cy-custom-icons") format("svg");
}

i.icon.cy-information-outline,
i.icon.cy-shield-block,
i.icon.cy-shield-check,
i.icon.cy-stroller-outline,
i.icon.cy-user-outline,
i.icon.cy {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.cy-information-outline:before {
    content: "\f101";
}
i.icon.cy-shield-block:before {
    content: "\f102";
}
i.icon.cy-shield-check:before {
    content: "\f103";
}
i.icon.cy-stroller-outline:before {
    content: "\f104";
}
i.icon.cy-user-outline:before {
    content: "\f105";
}
i.icon.cy:before {
    content: "\f106";
}
